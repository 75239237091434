import {Component, Input} from '@angular/core';
import {FiloraEvent} from '../../model/event/filora_event';
import {DateUtil} from '../../utils/date-util';
import {ActivatedRoute} from '@angular/router';

@Component({template: ''})
export class AbstractEventItemComponent {
  @Input() filoraEvent: FiloraEvent;

  constructor(private route: ActivatedRoute) {
  }

  eventIsToday(): boolean {
    return DateUtil.startsToday(this.filoraEvent.start_date);
  }

  openEventDetails(): void {
    const searchParams = new URLSearchParams(this.route.snapshot.queryParams);
    window.open(window.location.origin + '/plugin/event/' + this.filoraEvent.uuid + '/?' + searchParams.toString(), '_blank');
  }

  getStartDateAndTime(): string {
    return DateUtil.getFormattedDateAndTime(this.filoraEvent.start_date, 'en');
  }
}
