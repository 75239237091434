<div class="card filora-card" (click)="openEventDetails()">


  <div style="position:relative;">
    <!-- Placeholder Image-->
    <img class="image" *ngIf="!isImgLoaded" src="assets/placeholder.png" alt="">
    <!-- Event Image-->
    <img src="{{ filoraEvent.event_picture_link | filoraImagePipe }}" [hidden]="!isImgLoaded" alt="event picture"
         (load)="isImgLoaded = true">
    <!-- Today Badge-->
    <span *ngIf="eventIsToday() && isImgLoaded" class="badge badge-filora top-left">Today</span>
    <!-- Tag Badges-->
    <div *ngIf="isImgLoaded" class="bottom-left">
      <span *ngFor="let tag of filoraEvent.tags" class="badge badge-filora ml-1">#{{tag}}</span>
    </div>
  </div>


  <div class="card-body">

    <h5 class="card-title">
      <span class="restrict-len">{{filoraEvent.name}}</span>
    </h5>


    <h6 class="card-subtitle mb-2 text-muted">
      {{getStartDateAndTime()}} </h6>

    <div class="d-block-inline">
      <app-location-text [filoraLocation]="filoraEvent?.location[0]"></app-location-text>
    </div>

  </div>
</div>
