import {Component, OnInit, Input} from '@angular/core';
import {FiloraColors} from '../../utils/colors';

@Component({
  selector: 'app-from-filora',
  templateUrl: './from-filora.component.html',
  styleUrls: ['./from-filora.component.css']
})
export class FromFiloraComponent implements OnInit {

  @Input()
  backgroundColor = '#ffffff';
  @Input()
  fontColor = FiloraColors.darkColor;


  constructor() {
  }

  onClickFromFilora(): void {
    window.open('https://info.filora.eu', '_blank');
  }

  ngOnInit(): void {

  }


}


