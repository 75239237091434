<h2>Documentation (v.{{version}})</h2>

<h4>Events</h4>

<h6>Parameters</h6>

<ul>
  <li>backgroundColor: hex color 000000 - ffffff</li>
  <li>style: list, card, card-horizontal</li>

  <li>filter_host: uuid of host</li>
  <li>filter_start_date_gt:</li>
  <li>filter_tags: #TAG_1#TAG_2#TAG_N</li>
  <li>filter_domain: domain</li>

  <li>location_distance: distance in meters</li>
  <li>location_point: GPS coordinates</li>
</ul>
