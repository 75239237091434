import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css']
})
export class ImprintComponent implements OnInit {

  htmlContent: string;
  loading = true;

  constructor(private httpClient: HttpClient, private title: Title, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.httpClient.get('assets/imprint/filora.html', {responseType: 'text'}).subscribe(
      data => {
        this.loading = false;
        this.htmlContent = data;
        this.title.setTitle('Imprint');
      }
    );
  }

}
