<div *ngIf="filoraEvent" class="row pb-4" [style.color]="fontColor" [style.background-color]="backgroundColor">
  <div class="col-sm-0 col-md-2 col-lg-3 col-xl-3">

  </div>


  <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">

    <img class="image" *ngIf="!isImgLoaded" src="assets/placeholder.png" alt="">
    <img class="image" [hidden]="!isImgLoaded" src="{{ filoraEvent.event_picture_link | filoraImagePipe}}" alt=""
         (load)="isImgLoaded = true">

    <div class="detail-container">

      <h3 class="header">
        {{getEventName()}}
      </h3>

      <p class="shortDescription">
        {{getEventShortDescription()}}
      </p>

      <div *ngIf="ticket || website" class="pt-2 pb-1">
        <a *ngIf="ticket" type="button" class="btn btn-outline-dark mr-3" target="_blank" href="{{ticket}}"><i
          class="bi bi bi-ticket-perforated"></i> Tickets
        </a>
        <a *ngIf="website" type="button" class="btn btn-outline-dark mr-3" target="_blank" href="{{website}}"><i
          class="bi bi bi-link-45deg"></i>
          Website
        </a>
      </div>

      <h5 class="header">
        Date and Time
      </h5>
      <div>
        <p class="filora-margin">{{getDateAndTime()}}</p>
      </div>

      <h5 class="header">
        Location
      </h5>
      <div class="filora-margin">
        <app-location-text [filoraLocation]="filoraEvent?.location[0]"></app-location-text>
      </div>

      <h5 class="header">
        Price
      </h5>
      <p class="filora-margin">{{getPrice()}}</p>


      <h5 class="header" *ngIf="hasDescription()">Description</h5>
      <p class="filora-margin">
        {{getEventDescription()}}
      </p>

      <h5 *ngIf="email || phone" class="header">
        Contact
      </h5>
      <p *ngIf="email" class="filora-margin">Email: <a href="mailto:{{email}}">{{email}}</a></p>
      <p *ngIf="phone" class="filora-margin">Phone: <a href="tel:{{phone}}">{{phone}}</a></p>

    </div>

  </div>

  <div class="col-sm-0 col-md-2 col-lg-3 col-xl-3">

  </div>


</div>

<app-error-and-loading [fontColor]="fontColor" [backgroundColor]="backgroundColor"
                       [loading]="loading" [error]="error"></app-error-and-loading>
<app-from-filora [fontColor]="fontColor" [backgroundColor]="backgroundColor"></app-from-filora>
