<div *ngIf="loading || error" class="d-flex justify-content-center pt-4 pb-4"
     [style.background-color]="backgroundColor">
  <div *ngIf="loading" class="spinner-border" role="status" [style.color]="fontColor">
    <span class="sr-only">Loading...</span>
  </div>

  <div *ngIf="error" class="alert alert-danger" role="alert">
    {{ "Error (" + error.status + "): " + error.statusText}}
  </div>
</div>
