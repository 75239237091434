import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css']
})
export class TermsOfUseComponent implements OnInit {

  htmlContent: string;
  loading = true;

  constructor(private httpClient: HttpClient, private title: Title, private route: ActivatedRoute) { }

  ngOnInit(): void {

    const flavorDomain = this.route.snapshot.paramMap.get('flavorDomain');

    this.httpClient.get('assets/terms-of-use/' + flavorDomain + '.html', {responseType: 'text'}).subscribe(
      data => {
        this.loading = false;
        this.htmlContent = data;
        this.title.setTitle('Terms of Service ' + flavorDomain);
      }
    );
  }

}
