export class FiloraColors {
  static defaultBackground = '#ffffff';
  static darkColor = '#6e6e6e';
  static lightColor = '#ffffff';

  static computeFontColor(backgroundColor: string): string {
    const backgroundColorRGB = this.hexToRgb(backgroundColor);
    if (backgroundColorRGB) {
      const luma =
        0.2126 *
          FiloraColors.linearizeColorComponent(backgroundColorRGB.R / 255) +
        0.7152 *
          FiloraColors.linearizeColorComponent(backgroundColorRGB.G / 255) +
        0.0722 *
          FiloraColors.linearizeColorComponent(backgroundColorRGB.B / 255);
      if (luma > 0.5) {
        return FiloraColors.darkColor;
      } else {
        return FiloraColors.lightColor;
      }
    }
    return FiloraColors.darkColor;
  }

  // See <https://www.w3.org/TR/WCAG20/#relativeluminancedef>
  static linearizeColorComponent(component: number): number {
    if (component <= 0.03928) {
      return component / 12.92;
    }

    return Math.pow((component + 0.055) / 1.055, 2.4);
  }

  static hexToRgb(hex): RGBColor {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          R: parseInt(result[1], 16),
          G: parseInt(result[2], 16),
          B: parseInt(result[3], 16),
        }
      : null;
  }

  static isHexColor(hex): boolean {
    return (
      typeof hex === 'string' && hex.length === 6 && !isNaN(Number('0x' + hex))
    );
  }
}

type RGBColor = {
  R: number;
  G: number;
  B: number;
};
