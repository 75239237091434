import {Component, OnInit} from '@angular/core';
import { version } from '../../../../filora-web-plugin/package.json';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.css']
})
export class DocsComponent implements OnInit {

  version = version;
  constructor() {
  }

  ngOnInit(): void {
  }

}
