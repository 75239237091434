<div class="card" (click)="openEventDetails()">
  <div class="card-horizontal">
    <div>
      <img class="image" *ngIf="!isImgLoaded" src="assets/placeholder.png" alt="">
      <img src="{{ filoraEvent.event_picture_link | filoraImagePipe }}" [hidden]="!isImgLoaded" alt="event picture" (load)="isImgLoaded = true">
    </div>
    <div class="card-body align-items-center d-flex">
      <div>
        <span *ngIf=eventIsToday() class="badge badge-filora">Today</span>
        <h5 class="card-title">
          {{ filoraEvent.name }}
        </h5>

        <h6 class="card-subtitle mb-2 text-muted">
          {{getStartDateAndTime()}}
        </h6>
      </div>

    </div>
  </div>
</div>
