import {Component, OnInit} from '@angular/core';
import {AbstractEventItemComponent} from '../abstract-event-item.component';


@Component({
  selector: 'app-event-item-card',
  templateUrl: './event-item-card.component.html',
  styleUrls: ['./event-item-card.component.css']
})
export class EventItemCardComponent extends AbstractEventItemComponent implements OnInit {
  isImgLoaded = false;

  ngOnInit(): void {
  }
}

