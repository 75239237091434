import {StringUtility} from '../../utils/string-util';

export class FiloraLocation{
  uuid: string;
  // tslint:disable-next-line:variable-name
  owner: string;
  // tslint:disable-next-line:variable-name
  owner_type: string;
  data: FiloraLocationData;
  location: FiloraPoint;
}

export class FiloraLocationData{
  routeLongName: string;
  routeShortName: string;

  // tslint:disable-next-line:variable-name
  street_number: string;

  administrativeAreaLevel1Long: string;
  administrativeAreaLevel1Short: string;

  administrativeAreaLevel3Long: string;
  administrativeAreaLevel3Short: string;

  localityLongName: string;
  localityShortName: string;

  countryLongName: string;
  countryShortName: string;

  // tslint:disable-next-line:variable-name
  postal_code: string;
  placeId: string;
  sublocality: string;

  locationMainText: string;


  static mainText(locationData: FiloraLocationData): string{

    if (!StringUtility.isNullOrEmpty(locationData.locationMainText)){
      return locationData.locationMainText;
    }

    let res = '';
    if (!StringUtility.isNullOrEmpty(locationData.routeLongName)){
      res += locationData.routeLongName;
      if (!StringUtility.isNullOrEmpty(locationData.street_number)){
        res += ' ';
        res += locationData.street_number;
      }

    }else{
      res += locationData.localityLongName;
    }
    return res;
  }

}

export class FiloraPoint{
  type: string;
  coordinates: number[];
}
