import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FiloraEvent} from '../../../model/event/filora_event';
import {EventService} from '../../../service/event.service';
import {StringUtility} from '../../../utils/string-util';
import {environment} from '../../../../environments/environment';
import {DateUtil} from '../../../utils/date-util';
import {FiloraColors} from '../../../utils/colors';
import {ContactChoices, FiloraContactData} from '../../../model/contact-data/filora_contact_data';

// TODO:
// multilanguage
// loading and error

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {

  // Stores the event object
  filoraEvent: FiloraEvent;

  // Stores contact data information
  email: string;
  website: string;
  phone: string;
  ticket: string;

  backgroundColor = FiloraColors.defaultBackground;
  fontColor = FiloraColors.darkColor;
  loading = false;
  error: object;
  isImgLoaded = false;


  constructor(private route: ActivatedRoute, private eventService: EventService, @Inject(LOCALE_ID) public locale: string) {
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');

    // Apply custom backgroundColor
    const requestedColor = this.route.snapshot.queryParamMap.get('backgroundColor');
    if (requestedColor && FiloraColors.isHexColor(requestedColor)) {
      this.backgroundColor = '#' + requestedColor;
    }
    this.fontColor = FiloraColors.computeFontColor(this.backgroundColor);

    // Load Event
    this.loading = true;
    this.eventService.getEventById(id).subscribe((filoraEvent: FiloraEvent) => {
      this.filoraEvent = filoraEvent;
      this.loading = false;
    }, (err) => {
      this.error = err;
      this.loading = false;
    });

    // Load ContactData of Event
    this.eventService.getContactDataById(id).subscribe((contactDataList: FiloraContactData[]) => {
      for (const contactData of contactDataList) {
        console.log(contactData);
        if (contactData.type === ContactChoices.EMAIL) {
          this.email = contactData.value;
        }
        if (contactData.type === ContactChoices.URL) {
          this.website = contactData.value;
        }
        if (contactData.type === ContactChoices.TICKET) {
          this.ticket = contactData.value;
        }
        if (contactData.type === ContactChoices.PHONE) {
          this.phone = contactData.value;
        }
      }
    }, (err) => {
      this.error = err;
      this.loading = false;
    });
  }

  getEventName(): string {
    return this.filoraEvent != null ? this.filoraEvent.name : '';
  }

  getEventDescription(): string {
    return this.filoraEvent != null ? this.filoraEvent.description : '';
  }

  hasDescription(): boolean {
    return this.filoraEvent != null && !StringUtility.isNullOrEmpty(this.filoraEvent.description);
  }

  getEventShortDescription(): string {
    return this.filoraEvent != null ? this.filoraEvent.short_description : '';
  }

  getDateAndTime(): string {
    let res = '';
    if (this.filoraEvent != null) {
      res += DateUtil.getFormattedDateAndTime(this.filoraEvent.start_date, this.locale);
      if (this.filoraEvent.end_date != null) {
        res += ' - ';
        res += DateUtil.getFormattedDateAndTime(this.filoraEvent.end_date, this.locale);
      }
    }
    return res;
  }

  getPrice(): string {
    if (StringUtility.isNullOrEmpty(this.filoraEvent?.price)) {
      return '';
    }

    if (this.filoraEvent.price === '0.00') {
      return 'Free';
    }

    return this.filoraEvent.price + ' Euro';
  }

  getEventImage(): string {
    if (StringUtility.isNullOrEmpty(this.filoraEvent.event_picture_link)) {
      return environment.defaultEventImage;
    }
    return environment.backendUrl + this.filoraEvent.event_picture_link;
  }

}
