import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({name: 'filoraImagePipe'})
export class FiloraImagePipe implements PipeTransform {
  transform(absoluteLink: string): string {
    if (absoluteLink) {
      return absoluteLink;
    }
    return environment.defaultEventImage;
  }
}
