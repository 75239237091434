<div class="row pb-4">
  <div class="col-sm-0 col-md-2 col-lg-3 col-xl-3">

  </div>


  <div class="col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">

    <div class="text-container">


      <h3 class="text">
        This App is not available in the Web. Please open the link on your smartphone.
      </h3>

      <img class="image" src="assets/smartphone.webp" alt="">

      <app-from-filora></app-from-filora>


    </div>

  </div>

  <div class="col-sm-0 col-md-2 col-lg-3 col-xl-3">

  </div>

</div>
