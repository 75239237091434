import {Component, OnInit} from '@angular/core';
import {FiloraEvents} from '../../../model/event/filora_events';
import {EventService} from '../../../service/event.service';
import {ActivatedRoute} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {FiloraColors} from '../../../utils/colors';
import {FiloraEvent} from '../../../model/event/filora_event';
import {DateUtil} from '../../../utils/date-util';
import {PlatformCountService} from '../../../service/count.service';

enum EventStyle {
  CardVertical = 'card',
  CardHorizontal = 'card-horizontal',
  List = 'list',
}

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.css']
})
export class EventsListComponent implements OnInit {

  // Helper values
  currentPage: FiloraEvents;
  hasMoreEvents: boolean;
  loading = false;
  error: object;

  // Values to display
  displayedEvents: FiloraEvent[];
  backgroundColor = FiloraColors.defaultBackground;
  fontColor = FiloraColors.darkColor;
  eventStyle = EventStyle.CardVertical;

  constructor(private route: ActivatedRoute, private eventsService: EventService, private countService: PlatformCountService) {
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    // Count platform
    this.countService.count().subscribe();

    // Apply custom backgroundColor
    const requestedColor = this.route.snapshot.queryParamMap.get('backgroundColor');
    if (requestedColor && FiloraColors.isHexColor(requestedColor)) {
      this.backgroundColor = '#' + requestedColor;
    }

    // Compute fontColor based on background Color
    this.fontColor = FiloraColors.computeFontColor(this.backgroundColor);

    // Apply custom eventStyle
    const requestedStyle = this.route.snapshot.queryParamMap.get('style');
    if (requestedStyle && Object.values(EventStyle).includes(requestedStyle as EventStyle)) {
      this.eventStyle = requestedStyle as EventStyle;
    }

    // Request backend based on provided query parameters
    let queryParams = new HttpParams();
    queryParams = this.forwardQueryParams(queryParams, 'filter_host');
    queryParams = this.forwardQueryParams(queryParams, 'filter_start_date_gt');
    queryParams = this.forwardQueryParams(queryParams, 'filter_tags');
    queryParams = this.forwardQueryParams(queryParams, 'filter_domain');
    queryParams = this.forwardQueryParams(queryParams, 'location_distance');
    queryParams = this.forwardQueryParams(queryParams, 'location_point');

    // Set default start_date today
    if (!queryParams.has('filter_start_date_gt')) {
      queryParams = queryParams.set('filter_start_date_gt', DateUtil.getCurrentDate().toJSON());
    }

    await this.eventsService.getEvents(queryParams).subscribe((filoraEvents: FiloraEvents) => {
      this.currentPage = filoraEvents;
      this.displayedEvents = filoraEvents.results;
      this.hasMoreEvents = filoraEvents.next != null;
      this.loading = false;
    }, (err) => {
      this.error = err;
      this.loading = false;
    });


  }

  /** Loading additional Events if possible and append them into the displayedEvent list */
  async loadMoreEvents(): Promise<void> {
    if (this.hasMoreEvents) {
      this.loading = true;
      this.hasMoreEvents = false;
      await this.eventsService.getNextEvents(this.currentPage.next).subscribe((filoraEvents: FiloraEvents) => {
        this.currentPage = filoraEvents;
        this.displayedEvents = this.displayedEvents.concat(filoraEvents.results);
        this.hasMoreEvents = filoraEvents.next != null;
        this.loading = false;
      }, (err) => {
        this.error = err;
        this.loading = false;
      });
    }
  }

  /** Appends the requested query parameter to the given parameter set if the requested name is present in the present query parameters. */
  forwardQueryParams(queryParams: HttpParams, name: string): HttpParams {
    const value = this.route.snapshot.queryParamMap.get(name);

    if (value) {
      return queryParams.append(name, value);
    }
    return queryParams;
  }


}

