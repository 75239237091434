import { DatePipe } from '@angular/common';

export class DateUtil {
  constructor() {}

  static startsToday(startDate: Date): boolean {
    const currentDate = new Date();
    const eventDate = new Date(startDate);

    return (
      currentDate.getFullYear() === eventDate.getFullYear() &&
      currentDate.getDate() === eventDate.getDate() &&
      currentDate.getMonth() === eventDate.getMonth()
    );
  }

  static getFormattedDateAndTime(date: Date, locale: string): string {
    return new DatePipe(locale).transform(date, 'EEE, d. MMM hh:mm a', 'UTC');
  }

  static getFormattedDate(date: string, locale: string): string {
    return new DatePipe(locale).transform(date, 'dd MMM yyyy', 'UTC');
  }

  static getFormattedTime(time: string, locale: string): string {
    return new DatePipe(locale).transform(time, 'hh:mm a', 'UTC');
  }

  static getCurrentDate(): Date {
    const date = new Date();
    date.setMinutes(0);
    date.setHours(0);
    return date;
  }
}
