import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  htmlContent: string;
  loading = true;
  appName = '';

  constructor(private httpClient: HttpClient, private title: Title, private route: ActivatedRoute) { }

  ngOnInit(): void {

    const appName = this.route.snapshot.queryParamMap.get('appName');
    if (appName){
      this.appName = appName;
    }

    this.httpClient.get('assets/privacy-policy/filora.html', {responseType: 'text'}).subscribe(
      data => {
        this.loading = false;
        this.htmlContent = data;
        this.title.setTitle('Privacy Policy ' + appName);
      }
    );
  }
}
