import {Component, Input, OnInit} from '@angular/core';
import {FiloraColors} from '../../utils/colors';

@Component({
  selector: 'app-error-and-loading',
  templateUrl: './error-and-loading.component.html',
  styleUrls: ['./error-and-loading.component.css']
})
export class ErrorAndLoadingComponent implements OnInit {

  @Input()
  loading: boolean;
  @Input()
  error: object;
  @Input()
  backgroundColor = FiloraColors.defaultBackground;
  @Input()
  fontColor = FiloraColors.darkColor;

  constructor() {
  }

  ngOnInit(): void {
  }

}
