import {NgModule} from '@angular/core';
import {EventsListComponent} from './components/event/events-list/events-list.component';
import {RouterModule, Routes} from '@angular/router';
import {EventDetailComponent} from './components/event/event-detail/event-detail.component';
import {DocsComponent} from './docs/docs.component';
import {CannotOpenLinkBrowserComponent} from './components/cannot-open-link-browser/cannot-open-link-browser.component';
import {TermsOfUseComponent} from './terms-of-use/terms-of-use.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {ImprintComponent} from './imprint/imprint.component';

const routes: Routes = [
  {path: 'plugin/events', component: EventsListComponent},
  {path: 'plugin/event/:id', component: EventDetailComponent},
  {path: 'docs', component: DocsComponent},
  {path: 'noWebappAvailable', component: CannotOpenLinkBrowserComponent},
  {path: 'tos/:flavorDomain', component: TermsOfUseComponent},
  {path: 'privacy-policy/:flavorDomain', component: PrivacyPolicyComponent},
  {path: 'imprint/:flavorDomain', component: ImprintComponent},
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
