<div class="wrapper pb-4 pt-4" [style.background-color]="backgroundColor">
  <div (click)="onClickFromFilora()"
       style="cursor: pointer;">
    <div class="d-inline">
      <img class="mx-auto d-inline" src="../../../assets/filora_logo_round_corners.png" alt="k">
    </div>
    <div class="d-inline-block align-middle ml-2" [style]="'color:'+fontColor">
      <span class="d-block"> Powered by </span>
      <span class="d-block"> Filora </span>
    </div>
  </div>

</div>

