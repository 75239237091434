<div [style.background-color]="backgroundColor">

  <!--Empty result-->
  <div *ngIf="currentPage && currentPage.results.length==0" class="d-flex justify-content-center pt-4">
    <div class="alert alert-warning" role="alert">
      No Events match given filters.
    </div>
  </div>

  <!--  Show Events as vertical Cards-->
  <div *ngIf="eventStyle === 'card'" class="row">
    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3" *ngFor="let event of displayedEvents">
      <app-event-item-card [filoraEvent]="event"></app-event-item-card>
    </div>
  </div>

  <!--Error handling-->
  <app-error-and-loading *ngIf="eventStyle === 'card'" [fontColor]="fontColor" [backgroundColor]="backgroundColor"
                         [loading]="loading" [error]="error"></app-error-and-loading>

  <!--  END: Show Events as vertical Cards-->

  <!--  Show Events as horizontal Cards-->
  <div *ngIf="eventStyle === 'card-horizontal'">
    <div class="row flex-row flex-nowrap" style="overflow-x: scroll;">
      <div class="col-10 col-sm-6 col-md-4 col-lg-4 col-xl-3" *ngFor="let event of displayedEvents">
        <app-event-item-card [filoraEvent]="event" style="width: 200px"></app-event-item-card>
      </div>

      <!--"Show more Events" Button and "Powered by Filora" within scrollable row-->
      <div class="pr-4" style="min-width: 200px">
        <div *ngIf="hasMoreEvents" class="pt-4" style="text-align: center">
          <button (click)="loadMoreEvents()"
                  class="btn ma-8"
                  [style.border-color]="fontColor"
                  [style.color]="fontColor"
          >Show more Events
          </button>
        </div>

        <!--Error handling-->
        <app-error-and-loading [fontColor]="fontColor" [backgroundColor]="backgroundColor"
                               [loading]="loading" [error]="error"></app-error-and-loading>

        <app-from-filora [backgroundColor]="backgroundColor" [fontColor]="fontColor"></app-from-filora>
      </div>
    </div>
  </div>


  <div *ngIf="eventStyle === 'list'">
    <div *ngFor="let event of displayedEvents" style="padding: 1vw">
      <app-event-item-list [filoraEvent]="event"></app-event-item-list>
    </div>
  </div>


  <!--"Show more Events" Button and "Powered by Filora" are hidden for horizontal cards-->
  <div *ngIf="eventStyle !== 'card-horizontal'">

    <div *ngIf="hasMoreEvents" class="pt-4" style="text-align: center">
      <button (click)="loadMoreEvents()" type="button"
              [style.border-color]="fontColor"
              [style.color]="fontColor"
              class="btn">Show more Events
      </button>
    </div>

    <app-from-filora [backgroundColor]="backgroundColor" [fontColor]="fontColor"></app-from-filora>

  </div>


</div>




