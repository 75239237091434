import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {EventsListComponent} from './components/event/events-list/events-list.component';
import {AppRoutingModule} from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule} from '@angular/common/http';
import {EventDetailComponent} from './components/event/event-detail/event-detail.component';
import {FromFiloraComponent} from './components/from-filora/from-filora.component';
import {LocationTextComponent} from './components/location-text/location-text.component';
import {EventItemListComponent} from './components/event/event-item-list/event-item-list.component';
import {FiloraImagePipe} from './utils/filora-image-pipe';
import {EventItemCardComponent} from './components/event/event-item-card/event-item-card.component';
import {AbstractEventItemComponent} from './components/event/abstract-event-item.component';
import {DocsComponent} from './docs/docs.component';
import { ErrorAndLoadingComponent } from './components/error-and-loading/error-and-loading.component';
import { CannotOpenLinkBrowserComponent } from './components/cannot-open-link-browser/cannot-open-link-browser.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ImprintComponent } from './imprint/imprint.component';

@NgModule({
  declarations: [
    AppComponent,
    EventsListComponent,
    AbstractEventItemComponent,
    EventItemCardComponent,
    EventItemListComponent,
    EventDetailComponent,
    FromFiloraComponent,
    LocationTextComponent,
    FiloraImagePipe,
    DocsComponent,
    ErrorAndLoadingComponent,
    CannotOpenLinkBrowserComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    ImprintComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
