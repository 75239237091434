export enum ContactChoices {
  EMAIL = 'email',
  PHONE = 'phone',
  PERSON = 'person',
  URL = 'url',
  TICKET = 'ticket',
}

export class FiloraContactData {
  uuid: string;
  owner: string;
  // tslint:disable-next-line:variable-name
  owner_type: string;
  type: ContactChoices;
  value: string;
}
