import {
  FiloraLocation,
  FiloraLocationData,
} from '../location/filora-location';

export class FiloraEvent {
  uuid: string;
  name: string;
  // tslint:disable-next-line:variable-name
  event_picture_link: string;
  // tslint:disable-next-line:variable-name
  start_date: Date;
  price: string;
  description: string;
  // tslint:disable-next-line:variable-name
  short_description: string;
  // tslint:disable-next-line:variable-name
  end_date: Date;
  location: FiloraLocation[];
  tags: string[];

  static getLocationText(location: FiloraLocation): string {
    return FiloraLocationData.mainText(location.data);
  }

  isToday(): boolean {
    const currentDate = new Date();
    const eventDate = new Date(this.start_date);

    return (
      currentDate.getFullYear() === eventDate.getFullYear() &&
      currentDate.getDate() === eventDate.getDate() &&
      currentDate.getMonth() === eventDate.getMonth()
    );
  }
}
