
<div class="row pb-4">
  <div class="col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3"></div>

  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 inner-container">
    <div [innerHTML]="htmlContent"></div>
  </div>

  <div class="col-xs-0 col-sm-0 col-md-3 col-lg-3 col-xl-3"></div>
</div>
<app-error-and-loading [loading]="loading"></app-error-and-loading>
