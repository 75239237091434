import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FiloraEvents} from '../model/event/filora_events';
import {FiloraEvent} from '../model/event/filora_event';
import {FiloraContactData} from '../model/contact-data/filora_contact_data';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(private http: HttpClient) {
  }

  getEvents(queryParams: HttpParams): Observable<FiloraEvents> {

    queryParams = queryParams.append('ordering', 'start_date');
    return this.http.get<FiloraEvents>('/api/events/', {params: queryParams});
  }

  getNextEvents(next: string): Observable<FiloraEvents> {
    // The parameter next is an url directly to the backend. However, we want to proxy this URL (via '*/api/'
    // to avoid different behaviour in prod and dev.
    const url = new URL(next);
    return this.http.get<FiloraEvents>('/api' + url.pathname + '/?' + url.searchParams.toString());
  }

  getEventById(id: string): Observable<FiloraEvent> {
    return this.http.get<FiloraEvent>('/api/events/' + id + '/');
  }

  getContactDataById(id: string): Observable<FiloraContactData[]> {
    return this.http.get<FiloraContactData[]>('/api/events/' + id + '/contact_data/');
  }

}
