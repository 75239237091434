import {Component, Input, OnInit} from '@angular/core';
import {FiloraEvent} from '../../../model/event/filora_event';
import {AbstractEventItemComponent} from '../abstract-event-item.component';

@Component({
  selector: 'app-event-item-list',
  templateUrl: './event-item-list.component.html',
  styleUrls: ['./event-item-list.component.css']
})
export class EventItemListComponent extends AbstractEventItemComponent implements OnInit {
  @Input() filoraEvent: FiloraEvent;

  isImgLoaded = false;

  ngOnInit(): void {
  }

}
