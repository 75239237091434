import {Component, Input, OnInit} from '@angular/core';
import {FiloraLocation} from '../../model/location/filora-location';
import {FiloraEvent} from '../../model/event/filora_event';

@Component({
  selector: 'app-location-text',
  templateUrl: './location-text.component.html',
  styleUrls: ['./location-text.component.css']
})
export class LocationTextComponent implements OnInit {

  @Input() filoraLocation: FiloraLocation;

  constructor() {
  }

  ngOnInit(): void {
  }

  getLocationText(): string {
    if (this.filoraLocation != null) {
      return FiloraEvent.getLocationText(this.filoraLocation);
    }
    return '';
  }

  openLocation(): void {
    const long = this.filoraLocation.location.coordinates[0];
    const lat = this.filoraLocation.location.coordinates[1];
    window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${long}&query_place_id=${this.filoraLocation.data.placeId}`, '_blank');
  }

}
